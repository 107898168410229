import Cookies from 'js-cookie'; //https://github.com/js-cookie/js-cookie

export default class {

    constructor() {
        
        this.cookieName = '_raf_favouritesbutton';
        this.buttonContainer = $('.favourites-button-container');
        this.button = $('.favourites-button');
        this.breakpoint = 1026;        
        this.noText = this.button.find('span').text().length == 0;
        

        if (this.buttonContainer.hasClass('always-show') || this.hasFavourites()) {

            this.clickEvent();

            $(window).on('resize', (e) => {
                this.setButton();
            });

            if (window.innerWidth < this.breakpoint) {
                if (!Cookies.get(this.cookieName) && !this.noText) { 

                    setTimeout((e) => {
                        this.button.find('span').animate({'margin-left': 0}, 250);
                        this.button.find('span').animate({width: -28}, 500, (e) =>  {                                                        
                            this.button.find('span').removeAttr('style');                            
                            this.button.removeClass('showtext');                                    
                        });                                                
                        Cookies.set(this.cookieName, 'dismissed', {});
                    }, 3000);          

                } else {
                    this.button.removeClass('showtext');
                }            
            }
        } else {
            this.buttonContainer.hide();
        }   
    }

    clickEvent() {
        this.button.on('click', (e) => {
            let $target = $(e.currentTarget);
            if ($target.hasClass('showtext') || this.noText) {
                window.location.href = $target.attr('href');
            } else {
                $target.addClass('showtext');
                return false;
            }     
        });
    }

    setButton() {
        if (window.innerWidth >= this.breakpoint) {
            if (!this.button.hasClass('showtext'))
                this.button.addClass('showtext');
        } else {
            this.button.removeClass('showtext');
        }
    }

    hasFavourites() {
        let cookie = Cookies.get('_raf_favourites');
        if (cookie !== undefined) {
            return cookie.length > 0;
        };
        return false;
    }
}
