import Utilities from '../helpers/utilities';

export default class {

    constructor() {        
        if (this.needsSetting()) {
            this.utilities = new Utilities();                    
            this.cookieName = 'favourites';
            this.setFavourites();
        }
    }

    needsSetting() {
        var returnValue = true;
        $('.favourite-button').each(function() {
            if ($(this).prop('adds')) {
                returnValue = false;
            }
        });
        return returnValue;
    }

    setFavourites() {
        let cookie = this.utilities.getCookie(this.cookieName);
        if (cookie !== undefined) {
            $('.favourite-button').each(function() {
                let favouriteButton = $(this);
                let roleCookieValue = favouriteButton.data('value');
                if (cookie.indexOf(roleCookieValue) > -1) {                    
                    favouriteButton.prop('adds', false);
                } else {                    
                    favouriteButton.prop('adds', true);                    
                }
            });
        } else {
            this.utilities.setSessionCookie(this.cookieName, '');
            $('.favourite-button').each(function() {                
                $(this).prop('adds', true);
            });
        }
        this.setFavouriteClicks();
        this.setButtonClasses();
    }

    setFavouriteClicks() {
        $('.favourite-button').on('click', (e) => {
            e.preventDefault();
            let favouriteButton = $(e.currentTarget);
            let currentCookieValue = this.utilities.getCookie(this.cookieName);  
            let roleCookieValue = favouriteButton.data('value');
            
            if (favouriteButton.prop('adds')) {
                if (currentCookieValue.indexOf(roleCookieValue) < 0)                
                {
                    if (currentCookieValue.length > 0) {
                        this.utilities.setSessionCookie(this.cookieName, currentCookieValue + ',' + roleCookieValue);
                    } else {
                        this.utilities.setSessionCookie(this.cookieName, roleCookieValue);
                    }
                }
                $('.favourite-button[data-value="' + roleCookieValue + '"]').prop('adds', false);                
            } else {
                currentCookieValue = currentCookieValue.replace(roleCookieValue, '').replaceAll(',,', ',');
                if (currentCookieValue.endsWith(',')) {
                    currentCookieValue = currentCookieValue.substring(0, currentCookieValue.length - 1);
                }
                if (currentCookieValue.startsWith(',')) {
                    currentCookieValue = currentCookieValue.substring(1);
                }
                this.utilities.setSessionCookie(this.cookieName, currentCookieValue);   
                $('.favourite-button[data-value="' + roleCookieValue + '"]').prop('adds', true);
            } 
            
            this.setButtonClasses();
        });
    }

    setButtonClasses() {
        let favFound = false;        
        $('.favourite-button').each(function() {
            let favouriteButton = $(this);
            let favouriteParent = favouriteButton.parents('[data-favsort]').first();            
            if (favouriteParent.length > 0) {
                favouriteParent.data('favsort', favouriteButton.prop('adds') ? '1' : '0');
            }
            if (favouriteButton.prop('adds')) {
                favouriteButton.removeClass('active');                
                favouriteButton.find('.favourite-icon').removeClass('active');
                if (favouriteButton.hasClass('button')) {
                    favouriteButton.find('span').text(favouriteButton.data('addlabel'));
                    favouriteButton.removeClass('button-cta');
                    favouriteButton.addClass('button-white');
                }
            } else {
                favFound = true;
                favouriteButton.addClass('active');
                favouriteButton.find('.favourite-icon').addClass('active');
                if (favouriteButton.hasClass('button')) {
                    favouriteButton.find('span').text(favouriteButton.data('removelabel'));
                    favouriteButton.addClass('button-cta');
                    favouriteButton.removeClass('button-white');  
                }
            }
        });
        
        let noFavsBlock = $('.no-favourites');
        let favouritingCheckbox = $('#favouritesCheckbox')
        if (noFavsBlock.length > 0) {            
            if (favFound) {
                noFavsBlock.hide();
            } else {                
                if (favouritingCheckbox.length > 0 && favouritingCheckbox.is(':checked')) {
                    noFavsBlock.show();
                }
            }
        }
    }
}
