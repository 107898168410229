import Utilities from '../../helpers/utilities';

export default class {

    constructor() {

        console.log('AST loading...');
        
        this.fullscreenButton = $('#btnBeginTest');
        this.closeDAAButton = $('#btnDAAClose');
        this.nextQestionButton = $('.next-qestion');
        this.previousQuestionButton = $('.prev-question');
        this.revisitQuestionButton = $('.revisit-question');
        this.playSequenceButton = $('.play-sequence');
        this.nonDAAlink = $('.non-daa-link');
        this._utilities = new Utilities();
        this.questionId = 'VR';
        this.elepsed_secods = 0;
        this.updateClockInterval = null;
        this.timeLeft = 0;
        this.timeSpend = 0;        

        this.questions = [
            {
                id: "VR",
                name: 'Verbal Reasoning',
                totalSubquestions: 12,
                timeLimit: 540,
                desiredTime: 270,
                userTimeLeft: 0,
                userTime: 0,
                userScore: 0,
                allQuestions: [
                    {
                        id: "VRQ1",
                        allSubquestions: [
                            { number: 1, id: "VRQ1-1", correctAnswer: "E", userAnswer: "" },
                            { number: 2, id: "VRQ1-2", correctAnswer: "B", userAnswer: "" },
                            { number: 3, id: "VRQ1-3", correctAnswer: "C", userAnswer: "" },
                        ],
                    },
                    {
                        id: "VRQ2",
                        allSubquestions: [
                            { number: 4, id: "VRQ2-1", correctAnswer: "C", userAnswer: "" },
                            { number: 5, id: "VRQ2-2", correctAnswer: "D", userAnswer: "" },
                        ],
                    },
                    {
                        id: "VRQ3",
                        allSubquestions: [
                            { number: 6, id: "VRQ3-1", correctAnswer: "B", userAnswer: "" },
                            { number: 7, id: "VRQ3-2", correctAnswer: "B", userAnswer: "" },
                            { number: 8, id: "VRQ3-3", correctAnswer: "D", userAnswer: "" },
                        ],
                    },
                    {
                        id: "VRQ4",
                        allSubquestions: [
                            { number: 9, id: "VRQ4-1", correctAnswer: "E", userAnswer: "" },
                            { number: 10, id: "VRQ4-2", correctAnswer: "C", userAnswer: "" },
                            { number: 11, id: "VRQ4-3", correctAnswer: "A", userAnswer: "" },
                            { number: 12, id: "VRQ4-4", correctAnswer: "E", userAnswer: "" },
                        ],
                    },
                ],
            },
            {
                id: "NR1",
                name: 'Numerical Reasoning Part 1',
                totalSubquestions: 5,
                timeLimit: 90,
                desiredTime: 40,
                userTimeLeft: 0,
                userTime: 0,
                userScore: 0,
                allQuestions: [
                    {
                        id: "NR1Q1",
                        allSubquestions: [
                            { number: 13, id: "NR1Q1-1", correctAnswer: "B", userAnswer: "" },
                            { number: 14, id: "NR1Q1-2", correctAnswer: "A", userAnswer: "" },
                            { number: 15, id: "NR1Q1-3", correctAnswer: "C", userAnswer: "" },
                            { number: 16, id: "NR1Q1-4", correctAnswer: "A", userAnswer: "" },
                            { number: 17, id: "NR1Q1-5", correctAnswer: "D", userAnswer: "" },
                        ],
                    }
                ],
            },
            {
                id: "NR2",
                name: 'Numerical Reasoning Part 2',
                totalSubquestions: 9,
                timeLimit: 390,
                desiredTime: 198,
                userTimeLeft: 0,
                userTime: 0,
                userScore: 0,
                allQuestions: [
                    {
                        id: "NR2Q1",
                        allSubquestions: [
                            { number: 18, id: "NR2Q1-1", correctAnswer: "C", userAnswer: "" },
                            { number: 19, id: "NR2Q1-2", correctAnswer: "A", userAnswer: "" },
                        ],
                    },
                    {
                        id: "NR2Q2",
                        allSubquestions: [
                            { number: 20, id: "NR2Q2-1", correctAnswer: "E", userAnswer: "" },
                            { number: 21, id: "NR2Q2-2", correctAnswer: "D", userAnswer: "" },
                        ],
                    },
                    {
                        id: "NR2Q3",
                        allSubquestions: [
                            { number: 22, id: "NR2Q3-1", correctAnswer: "C", userAnswer: "" },
                            { number: 23, id: "NR2Q3-2", correctAnswer: "D", userAnswer: "" },
                            { number: 24, id: "NR2Q3-3", correctAnswer: "C", userAnswer: "" },
                            { number: 25, id: "NR2Q3-4", correctAnswer: "B", userAnswer: "" },
                            { number: 26, id: "NR2Q3-5", correctAnswer: "A", userAnswer: "" },
                        ],
                    }
                ],
            },
            {
                id: "WR",
                name: 'Work Rate',
                totalSubquestions:5, 
                timeLimit:60, 
                desiredTime:25, 
                userTimeLeft:0, 
                userTime:0, 
                userScore:0,
                allQuestions: [
                    { 
                        id:'WRQ1',
                        allSubquestions:[
                            { number:27, id:'WRQ1-1', correctAnswer:'B', userAnswer:'' },
                            { number:28, id:'WRQ1-2', correctAnswer:'A', userAnswer:'' },
                            { number:29, id:'WRQ1-3', correctAnswer:'D', userAnswer:'' },
                            { number:30, id:'WRQ1-4', correctAnswer:'D', userAnswer:'' },
                            { number:31, id:'WRQ1-5', correctAnswer:'C', userAnswer:'' },
                        ]
                    }
                ],
            },
            {
                id: "SR1",
                name: 'Spatial Reasoning Part 1',
                totalSubquestions:4, 
                timeLimit:96, 
                desiredTime:25, 
                userTimeLeft:0, 
                userTime:0, 
                userScore:0,
                allQuestions: [
                    { 
                        id:'SR1Q1',
                        allSubquestions:[
                            { number:32, id:'SR1Q1-1', correctAnswer:'D', userAnswer:'' },
                            { number:33, id: 'SR1Q1-2', correctAnswer:'D', userAnswer: '' },
                            { number:34, id: 'SR1Q1-3', correctAnswer:'C', userAnswer: '' },
                            { number:35, id: 'SR1Q1-4', correctAnswer:'B', userAnswer: '' },
                        ]
                    }
                ],
            },
            {
                id: "SR2",
                name: 'Spatial Reasoning Part 2',
                totalSubquestions:4, 
                timeLimit:72, 
                desiredTime:17, 
                userTimeLeft:0, 
                userTime:0,
                userScore:0,
                allQuestions: [
                    { 
                        id:'SR2Q1',
                        allSubquestions:[
                            { number:36, id:'SR2Q1-1', correctAnswer:'A', userAnswer:'' },
                            { number:37, id: 'SR2Q1-2', correctAnswer:'C', userAnswer: '' }, 
                            { number:38, id: 'SR2Q1-3', correctAnswer:'B', userAnswer: '' },
                            { number:39, id: 'SR2Q1-4', correctAnswer:'E', userAnswer: '' },
                        ]
                    }
                ],
            },
            {
                id: "EC",
                name: 'Electrical Comprehension',
                totalSubquestions:4, 
                timeLimit:150, 
                desiredTime:17, 
                userTimeLeft:0, 
                userTime:0,
                userScore:0,
                allQuestions: [
                    { 
                        id:'ECQ1',
                        allSubquestions:[
                            { number:40, id:'ECQ1-1', correctAnswer:'D', userAnswer:'' },
                            { number:41, id:'ECQ1-2', correctAnswer:'C', userAnswer:'' },
                            { number:42, id:'ECQ1-3', correctAnswer:'B', userAnswer:'' },
                            { number:43, id:'ECQ1-4', correctAnswer:'C', userAnswer:'' }
                        ]
                    }
                ],
            },
            {
                id: "MC",
                name: 'Mechanical Comprehension',
                totalSubquestions:4, 
                timeLimit:150, 
                desiredTime:83, 
                userTimeLeft:0, 
                userTime:0, 
                userScore:0,
                allQuestions: [
                    { 
                        id:'MCQ1',
                        allSubquestions:[
                            { number:44, id:'MCQ1-1', correctAnswer:'C', userAnswer:'' },
                            { number:45, id:'MCQ1-2', correctAnswer:'A', userAnswer:'' },
                            { number:46, id:'MCQ1-3', correctAnswer:'B', userAnswer:'' },
                            { number:47, id:'MCQ1-4', correctAnswer:'E', userAnswer:'' }
                        ]
                    }
                ],
            },            
        ]

        this.isMobile = window.matchMedia(`(max-width: 744px)`)


        this.sliderEvents();
        this.clickEvents();
        //no need for scroll events for now
        //this.scrollEvents();
        
        this.addAccessibilityTabFocus();

        this.skipQuestion();
        this.keyboardShortcutToQuestion();
    }

    showTimer() {
        $('.timer').addClass('active');
    }

    hideTimer() {
        $('.timer').removeClass('active');
    }

    skipQuestion() {
        const qID = this._utilities.getUrlVars()['id'];

        if(qID !== undefined && qID !== null) {
            const $question = $(`#${qID}`);
            const id = $question.data('id');
    
            this.questionId = id;
    
            $('.airman-selection-test-20-page').hide();
    
            $question.show();
        }
    }

    keyboardShortcutToQuestion() {

        const devMode = this._utilities.getUrlVars()['dev'];
        if (devMode === '1') { 
            $("body").keydown(function(e){
                switch(e.keyCode) {
                    case 49: skipToQuestion('VRBEGIN'); break; //1
                    case 50: skipToQuestion('NR1BEGIN'); break; //2
                    case 51: skipToQuestion('NR2BEGIN'); break; //3
                    case 52: skipToQuestion('WRBEGIN'); break; //4
                    case 53: skipToQuestion('SR1BEGIN'); break; //5
                    case 54: skipToQuestion('SR2BEGIN'); break; //6
                    case 55: skipToQuestion('ECBEGIN'); break; //7
                    case 56: skipToQuestion('MCBEGIN'); break; //8
                    case 57: {
                        //9
                        skipToQuestion('SUM');
                        setAllBackButtonsSummary();
                        disableAllRadioButtons();
                        showQuestionOneSummaryButtons();
                        hideAllTests();
                        addPaddingNoTimerOS();
                     } 
                     break; 
                }
            });        
        }


        function skipToQuestion(inID){
            if(inID !== undefined && inID !== null) {
                const $question = $(`#${inID}`);
     
                $('.airman-selection-test-20-page').hide();

                const section = $question.data('section');
                if (section !== undefined) {
                    $('.section-number').text(section);
                } else {
                    $('.section-number').text('');
                }

                $('.timer').removeClass('active');
                $('.counter').removeClass('active');
                $('.questionPlaceholder').text('');
                $('.currentQuestion').text('');
                $('.totalQuestions').text('');               
        
                $question.show();
            }
        }

        function setAllBackButtonsSummary() {
            //hide next buttons
            $('.prev-question').each(function() {
                $(this).data('next', 'SUM');
                
                if ($(this).data('hide') == undefined) {                
                    $(this).data('hide', $(this).data('qestion-id'));
                }

                $(this).data('os', true);
                $(this).data('last', true);
                $(this).data('section', '');            
                $(this).text('Back to Summary');
            })
    
            $('.btn-20-secondary-red').each(function() {
                $(this).hide();
            });
        }
    
        function disableAllRadioButtons() {
            $('.ansverRadio').each(function() {
                $(this).prop('disabled', true);
            });
        }

        function showQuestionOneSummaryButtons() {
            $('.overlay-summary-buttons').each(function() {
                $(this).show();
            });
        }

        function hideAllTests() {
            $('.prev-question').each(function() {

                var questionId = $(this).data('hide-question-id');
                if (questionId != undefined) {
                    questionId = $(`#${questionId}`);

                    questionId.hide();
                }
                
                var current = $(this).data('current');
                if (current != undefined) { 
                    current = $(`#${current}`);

                    current.hide();
                }
            });
        }

        function addPaddingNoTimerOS() {            
            $('#VRQ1').addClass('no-timer-padding-top-smaller');
            $('#VRQ2').addClass('no-timer-padding-top-smaller');
            $('#VRQ3').addClass('no-timer-padding-top-smaller');
            $('#VRQ4').addClass('no-timer-padding-top-smaller');
            $('#NR1Q1').addClass('no-timer-padding-top');
            $('#NR2Q1').addClass('no-timer-padding-top');
            $('#NR2Q2').addClass('no-timer-padding-top');
            $('#NR2Q3').addClass('no-timer-padding-top');
            $('#WRQ1').addClass('no-timer-padding-top');
            $('#SR1Q1').addClass('no-timer-padding-top');
            $('#SR2Q1').addClass('no-timer-padding-top');
            $('#ECQ1').addClass('no-timer-padding-top');
            $('#MCQ1').addClass('no-timer-padding-top');            
        }
    }

    clickEvents() {        
        this.nextQestionButton.on('click', (e) => {
            e.preventDefault();

            const $target = $(e.currentTarget);
            const id = $target.data('id');
            let next = $target.data('next');
            let current = $target.data('current');
            const answer = $(`input[name=${current}]:checked`).val();
            const last = $target.data('last') !== undefined ? true : false;
            const summary = $target.data('summary') !== undefined ? true : false;
            let hide = $target.data('hide');
            let question = $target.data('qestion-id');
            const os = $target.data('os') !== undefined ? true : false; //overlay summary

            if(id !== undefined) {
                this.questionId = id;
            }

            //set answer
            if(current !== undefined) {
                this.setAnswer(id, question, current, answer);
            }
            
            if(current !== undefined) {
                current = $(`#${current}`);
            }
            question =  $(`#${question}`);
            hide =  $(`#${hide}`);

            //Show sumary
            if(summary && !os) {
                this.showSummary(id, next);
            }

            //Show Overlay Summary
            if(os) {
                this.ovarlaySummary();

                //set all Next buttons to Show Summary
                this.setAllBackButtonsSummary();
                this.disableAllRadioButtons();
                this.showQuestionOneSummaryButtons();
                this.hideAllTests();
                this.addPaddingNoTimerOS();
            }

            //Proces to next qestion / change qestion
            this.processAnswer(question, current, next, last, hide, answer);
        });

        this.previousQuestionButton.on('click', (e) => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            this.goToPrevious($target);
        });
        
        this.revisitQuestionButton.on('click', (e) => {
            e.preventDefault();
            const $target = $(e.currentTarget);
            this.goToPrevious($target);
        });
        
        this.playSequenceButton.on('click', (e) => {
            e.preventDefault();

            const $target = $(e.currentTarget);
            const sequence = $target.data('sequence');
            const question = $target.data('question');
            const $sequence = $(`#${sequence}`);
            const $question = $(`#${question}`);

            $target.hide();

            this.playSequence($sequence, $question);
        });

        this.fullscreenButton.on('click', (e) => {
            e.preventDefault();

            this.fullScreen();
        });

        this.closeDAAButton.on('click', (e) => {
            e.preventDefault();

            if (!window.close()) {
                this.exitFullScreen();
            }            
        });

        this.nonDAAlink.on('click', (e) => {
            this.exitFullScreen();
        });
    }   

    playSequence($sequence, $question) {

        const $frames = $sequence.children();
        const totalFrames = $frames.length;
        let timeOut = 1000;
        
        //show first one
        $frames.hide();
        $sequence.show();
        $($frames[0]).fadeIn(500);

        //loop next one
        $frames.each((index, value) => {
            const $el = $(value);
            const $next = $el.next();

            setTimeout(() => {

                $el.hide();
            
                if($next !== undefined) {
                    $next.fadeIn(500); 
                }

                if(index === (totalFrames - 1)) {
                    $sequence.hide();
                    $question.fadeIn(100);
                }
                
            }, timeOut * (index+1));
        }); 

    }
    

    startClock() {

        if (!this.isMobile.matches) {            
            $('.time-left').text("Time remaining for this section: ");
            $("#timeRemainingBar").width('0%');
        } else {
            $('.time-left').text("00:00");
        }
        
        this.showTimer();

        const firstQuestion = this._utilities.findInArray(this.questions, this.questionId);
        this.timeLeft = firstQuestion.timeLimit;

        if(this.updateClockInterval == null) {
            this.updateClockInterval = setInterval(this.updateClock.bind(this), 1000)
        }
    }

    updateClock() {
        this.timeSpend = this.timeSpend + 1;

        if(this.timeLeft > 0 ) {
            this.timeLeft = this.timeLeft - 1;

            if (this.isMobile.matches) {
                $('.time-left').text(this.get_elapsed_time_string(this.timeLeft));      
            }
            else
            {
                $('.time-left').text("Time remaining for this section: " + this.get_elapsed_time_string(this.timeLeft));      
            }                  
        }

        const firstQuestion = this._utilities.findInArray(this.questions, this.questionId);
        const totalValue = firstQuestion.timeLimit;

        // update progress bar on desktop only
        if (!this.isMobile.matches) {
            this.updateProgressBar(this.timeLeft, totalValue)
        }      
    }

    resetClock() {
        this.timeLeft = 0;
        this.timeSpend = 0;
        clearInterval(this.updateClockInterval);
        this.updateClockInterval = null;

        $('.time-left').text("00:00");

        this.hideTimer();
    }

    updateProgressBar(partialValue, totalValue) {
        var width = this.percentage(partialValue, totalValue);
        $("#timeRemainingBar").width(width + '%');
    }

    percentage(partialValue, totalValue) {
        var percentage = (100 * partialValue) / totalValue;
        var reversePercentage = 100 - percentage;
        return reversePercentage;
    } 

    processAnswer($question, $current, next, last, $hide, answer) {
        
        const elNext = $(`#${next}`);

        const section = elNext.data('section');
        if (section !== undefined) {
            $('.section-number').text(section);
        }
        
        if(last) {
            const firstSectionQuestion = $(`#${next + "-1"}`);
            const title = firstSectionQuestion.data('title');
            
            if (title !== undefined) {
                const questionNumber = title.split('/')[0].replace('question ', '');;
                const totalQuestions = title.split('/')[1];
                $('.questionPlaceholder').text(title.split(' ')[0]);
                $('.currentQuestion').text(questionNumber);
                $('.totalQuestions').text(totalQuestions);
                $('.counter').addClass('active');
            }
            else
            {            
                $('.counter').removeClass('active');
                $('.questionPlaceholder').text('');
                $('.currentQuestion').text('');
                $('.totalQuestions').text('');
            }
        }

        //change question
        if(answer !== undefined) { 
            //show hide sub qestion
            if($current !== undefined) {
                $current.hide();
            }

            let offset = 120;
                    
            if(last) {
                $hide.hide();
                offset = 200;
                
                //set question number
                const firstSectionQuestion = $(`#${next + "-1"}`);
                firstSectionQuestion.show();

            } else {
                //set question number
                const title = elNext.data('title');
                if (title !== undefined) {
                    const questionNumber = title.split('/')[0].replace('question ', '');;
                    const totalQuestions = title.split('/')[1];
                    $('.questionPlaceholder').text(title.split(' ')[0]);
                    $('.currentQuestion').text(questionNumber);
                    $('.totalQuestions').text(totalQuestions);
                    $('.counter').addClass('active');
                }
                else
                {            
                    $('.counter').removeClass('active');
                    $('.questionPlaceholder').text('');
                    $('.currentQuestion').text('');
                    $('.totalQuestions').text('');
                }
            }

            //proceed to next question
            elNext.fadeIn();            
            this._utilities.scrollTo(elNext, offset);
        }
        //jump from summary to qestion
        else if(answer === undefined && $current === undefined) { 
            $hide.hide();
            elNext.fadeIn();
            this._utilities.scrollTo(elNext, 200);

            //change question index
            this.setCurrentQestion(elNext);            
        }
    }

    previousQuestion($question, $next, $hide, $hideQuestionId, $current, $section) {
        //previous question
        if ($hideQuestionId !== undefined) {
            $hideQuestionId.hide();            
        }
        
        const nextId = $next[0].id;

        if ($question !== undefined && nextId != 'SUM') {
            $question.show();
        }

        if ($hide !== undefined) {
            $hide.hide();
        }

        if (nextId == 'SUM' && $current !== undefined) {
            $current.hide();            
        }

        //set section title
        if ($section !== undefined) {
            $('.section-number').text($section);
        }

        //set question number
        const title = $next.data('title');
        if (title !== undefined) {
            const questionNumber = title.split('/')[0].replace('question ', '');;
            const totalQuestions = title.split('/')[1];
            $('.questionPlaceholder').text(title.split(' ')[0]);
            $('.currentQuestion').text(questionNumber);
            $('.totalQuestions').text(totalQuestions);
            $('.counter').addClass('active');
        }
        else
        {            
            $('.counter').removeClass('active');
            $('.questionPlaceholder').text('');
            $('.currentQuestion').text('');
            $('.totalQuestions').text('');
        }

        $next.fadeIn();
        this._utilities.scrollTo($next, 120);
    }

    setCurrentQestion($next) {
        const id = $next.data('id');
        const startClock = $next.data('clock') !== undefined ? true : false;
        this.questionId = id;
        
        //start new time
        if(startClock) {
            this.startClock();
        }
    }

    setAnswer(id, questionId, currentId, answer) {
        if(answer !== undefined) {
            const qestion = this._utilities.findInArray(this.questions, id)
            const current = this._utilities.findInArray(qestion.allQuestions, questionId)
            const subQuestion = this._utilities.findInArray(current.allSubquestions, currentId)
            subQuestion.userAnswer = answer;            

            $(`#${currentId + "-answered"}`).removeClass('revisit-question-answered-no');
            $(`#${currentId + "-answered"}`).addClass('revisit-question-answered');
            $(`#${currentId + "-answered"}`).text("YES");
        } else {
            $(`#${currentId + "-answered"}`).addClass('revisit-question-answered-no');
            $(`#${currentId + "-answered"}`).text("NO");
        }  
    }

    showSummary(id, next) {

        const elNext = $(`#${next}`);

        const qestion = this._utilities.findInArray(this.questions, id);
        const $userScore = elNext.find('.userScore');
        const $tatalQestions = elNext.find('.tatalQestions');
     
        //reset user score incase they go back and resubmit answers
        qestion.userScore = 0;

        //Sub Questions
        $.each(qestion.allQuestions, (i, question) => {
            $.each(question.allSubquestions, (k, subQuestion) => {
                //Correct answer
                if(subQuestion.correctAnswer === subQuestion.userAnswer) {
                    qestion.userScore++;
                }
            });
        });

        //Update user score
        $userScore.text(this.pretty_number_string(qestion.userScore));
        
        //get total qestion count
        $tatalQestions.text(this.pretty_number_string(qestion.totalSubquestions));

        //update User time
        qestion.userTimeLeft = this.timeLeft;
        
        if(this.timeLeft == 0 && this.timeSpend > qestion.timeLimit) {
            qestion.userTime = this.timeSpend;
        }
        else if(this.timeLeft > 0) {
            qestion.userTime = qestion.timeLimit - qestion.userTimeLeft;
        }

        elNext.find('.time').text(this.get_elapsed_time_string(qestion.userTime));
        
        //reset time
        this.resetClock();

        this.calculateOverallScores();
    }

    ovarlaySummary() {
        this.calculateOverallScores();
    }

    calculateOverallScores() {
        let time = 0;
        let correctAnswers = 0;

        $.each(this.questions, (index, value) => {

            //Question
            const $qSum = $(`#${value.id}-FS`);
            time += value.userTime;

            $qSum.find('.time').text(this.get_elapsed_time_string(value.userTime));
            $qSum.find('.userScore').text(this.pretty_number_string(value.userScore));
            $qSum.find('.tatalQestions').text(this.pretty_number_string(value.totalSubquestions));
            
            //Sub Questioons
            $.each(value.allQuestions, (i, question) => {
                $.each(question.allSubquestions, (k, subQuestion) => {

                    $(`#${subQuestion.id}-answer`).text(`${subQuestion.userAnswer}`);

                    //Correct answer
                    if(subQuestion.correctAnswer === subQuestion.userAnswer) {

                        $(`#${subQuestion.id}-answer-icon`).removeClass('incorrect');
                        $(`#${subQuestion.id}-answer-note`).html('');

                        $(`#${subQuestion.id}-answer-icon`).addClass('correct');
                        $(`#${subQuestion.id}-answer-icon`).html('<span>✓</span>');
                        $(`#${subQuestion.id}-answer`).text(`${subQuestion.userAnswer}`);
                        correctAnswers++;
                    }
                    //Incorect answer
                    else {
                        $(`#${subQuestion.id}-${subQuestion.correctAnswer}`).addClass('correct-answer');
                        $(`#${subQuestion.id}-${subQuestion.userAnswer}`).addClass('incorrect');

                        $(`#${subQuestion.id}-answer-icon`).removeClass('correct');

                        $(`#${subQuestion.id}-answer-icon`).addClass('incorrect');
                        $(`#${subQuestion.id}-answer-icon`).html('<span>✕</span>');
                        
                        $(`#${subQuestion.id}-answer-note`).addClass('correct-answer');
                        $(`#${subQuestion.id}-answer-note`).html(`<span>${subQuestion.correctAnswer}</span> is the correct answer`);
                    }
                    
                });
            });
        });

        $('#SUM').find('.time-f').text(this.get_elapsed_time_string(time));
        $('#SUM').find('.userScore-f').text(this.pretty_number_string(correctAnswers));
    }

    setAllBackButtonsSummary() {
        //hide next buttons
        $('.prev-question').each(function() {
            $(this).data('next', 'SUM');

            if ($(this).data('hide') == undefined) {                
                $(this).data('hide', $(this).data('qestion-id'));
            }

            $(this).data('os', true);
            $(this).data('last', true);
            $(this).data('section', '');            
            $(this).text('Back to Summary');
        })

        $('.btn-20-secondary-red').each(function() {
            $(this).hide();
        });
    }

    disableAllRadioButtons() {
        $('.ansverRadio').each(function() {
            $(this).prop('disabled', true);
        });
    }

    showQuestionOneSummaryButtons() {
        $('.overlay-summary-buttons').each(function() {
            $(this).show();
        });
    }

    hideAllTests() {
        $('.prev-question').each(function() {

            var questionId = $(this).data('hide-question-id');
            if (questionId != undefined) {
                questionId = $(`#${questionId}`);

                questionId.hide();
            }
            
            var current = $(this).data('current');
            if (current != undefined) { 
                current = $(`#${current}`);

                current.hide();
            }
        });
    }

    addPaddingNoTimerOS() {            
        $('#VRQ1').addClass('no-timer-padding-top-smaller');
        $('#VRQ2').addClass('no-timer-padding-top-smaller');
        $('#VRQ3').addClass('no-timer-padding-top-smaller');
        $('#VRQ4').addClass('no-timer-padding-top-smaller');
        $('#NR1Q1').addClass('no-timer-padding-top');
        $('#NR2Q1').addClass('no-timer-padding-top');
        $('#NR2Q2').addClass('no-timer-padding-top');
        $('#NR2Q3').addClass('no-timer-padding-top');
        $('#WRQ1').addClass('no-timer-padding-top');
        $('#SR1Q1').addClass('no-timer-padding-top');
        $('#SR2Q1').addClass('no-timer-padding-top');
        $('#ECQ1').addClass('no-timer-padding-top');
        $('#MCQ1').addClass('no-timer-padding-top');            
    }

    scrollEvents() {
        $(window).scroll(function () {

            var s = $(window).scrollTop();
            if (s > 0) {
                $(".timer").addClass("fixed");
            }
            else {
                $(".timer").removeClass("fixed");
            }
        });
    }

    sliderEvents() {

        $("input[type=range].slider").val(0);

        $('input[type=range].slider').on('input', function () {

            var id = $(this).attr("data-slider-for");
            var range_value = $(this).val();
            var scroll_element = $("#" + id + " .overflow-child");
            
            var w = $(window).width(); 
            //var scroll_element_width = scroll_element.width();
            //var scroll_element_width = 744 + 75;
            var scroll_element_width = scroll_element.width() + 76; // AARON INCREASED THIS BT JUST 1 AS THATS ALL THAT SEEMED TO BE NEEDED

            var travel_value = scroll_element_width - w;
            travel_value = Math.floor(travel_value / 100);
            travel_value = travel_value * range_value;
            //travel_value += 50;
            
            $(scroll_element).css("margin-left", "-" + travel_value + "px");
            //console.log(id + " " + range_value + " " + scroll_element_width + " " + travel_value);
        });


        $(window).resize(function () {
            $('.overflow-child').css("margin-left", "0px");
            $("input[type=range].slider").val(0);
        });
        

    }

    get_elapsed_time_string(total_seconds) {

		let hours = Math.floor(total_seconds / 3600);
		total_seconds = total_seconds % 3600;

		let minutes = Math.floor(total_seconds / 60);
		total_seconds = total_seconds % 60;

		let seconds = Math.floor(total_seconds);

		// Pad the minutes and seconds with leading zeros, if required
		hours = this.pretty_number_string(hours);
		minutes = this.pretty_number_string(minutes);
		seconds = this.pretty_number_string(seconds);

		// Compose the string for display
        let currentTimeString = "00:00"
        if (this.isMobile.matches) {
            currentTimeString = minutes + ":" + seconds;
        } else {
            currentTimeString = minutes + "m " + seconds + "s";
        }
		
		return currentTimeString;
    }
    
    pretty_number_string(num) {
        return ( num < 10 ? "0" : "" ) + num;
    }

    goToPrevious($target) {
        const id = $target.data('id');
        let next = $target.data('next');
        const summary = $target.data('summary') !== undefined ? true : false;
        let hide = $target.data('hide');
        let question = $target.data('qestion-id');
        const os = $target.data('os') !== undefined ? true : false; //overlay summary
        let hideQuestionId = $target.data('hide-question-id');
        let current = $target.data('current');   
        let section = $target.data('section');     

        if(id !== undefined) {
            this.questionId = id;
        }

        if(current !== undefined) {
            current = $(`#${current}`);
        }

        if (next.toUpperCase().includes("BEGIN")) {
            this.resetClock();
        }

        //precess to nex qestion
        next = $(`#${next}`);
        
        if (section == undefined) {
            section = next.data('section');            
        }

        question =  $(`#${question}`);
        hide =  $(`#${hide}`);

        //Show sumary
        if(summary && !os) {
            this.showSummary(id, next);
        }

        //Show Overlay Summary
        if(os) {
            this.ovarlaySummary();
        }

        if (hideQuestionId !== undefined) {
            hideQuestionId = $(`#${hideQuestionId}`);
        }

        //Proces to next qestion / change qestion
        this.previousQuestion(question, next, hide, hideQuestionId, current, section);
    }

    addAccessibilityTabFocus() {
        var tabindex = 1;
        $('.ansverRadio, .btn-20-secondary, .btn-20-secondary-red, .revisit-question').each(function() {
            if (this.type != "hidden") {
                var $input = $(this);
                $input.attr("tabindex", tabindex);
                tabindex++;            
            }
        });
    }
    
    fullScreen() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen))
        {
            if (document.documentElement.requestFullScreen){
                document.documentElement.requestFullScreen();
            }
            else if (document.documentElement.mozRequestFullScreen){ /* Firefox */
                document.documentElement.mozRequestFullScreen();
            }
            else if (document.documentElement.webkitRequestFullScreen){   /* Chrome, Safari & Opera */
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
            else if (document.msRequestFullscreen){ /* IE/Edge */
                document.documentElement.msRequestFullscreen();
            }
        }
    }
    
    exitFullScreen() {
        if (document.cancelFullScreen){
            document.cancelFullScreen();
        }
        else if (document.mozCancelFullScreen){ /* Firefox */
            document.mozCancelFullScreen();
        }
        else if (document.webkitCancelFullScreen){   /* Chrome, Safari and Opera */
            document.webkitCancelFullScreen();
        }
        else if (document.msExitFullscreen){ /* IE/Edge */
            document.msExitFullscreen();
        }
    }
}