//Basic page styles
import './sass/style.scss'

//App Libaries
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'jquery-ajax-unobtrusive';
import 'slick-carousel/slick/slick';
import Utilities from './helpers/utilities';

//App Components
import LightBox from './components/lightBox';
import Navigation from './components/navigation';
import CookieWarning from './components/cookie-warning';
import LightBoxLity from './components/lightBoxLity';
import TabedModule from './components/tabedModule';
import EventsModule from './components/eventsModule';
import Carousel from './components/carousel';
import Carousel30 from './components/carousel30';
import VideoCarousel from './components/video-carousel';
import VideoSingle from './components/videosingle';
import ExpandedContent from './components/expanded-content';
import RoleDetails from './components/role-details';
import Modal from './components/modal'
import Raf15sliderButton from './components/raf15sliderButton';
import TrackingCodes from './components/tracking-codes';

//Engine
import SoundSkills from './engine/soundskills';

//2.0
import Header from './components/header';
import heroSection20 from './components/design20/heroSection20';
import airmanSelectionTest20 from './components/design20/airmanSelectionTest20';
import events20 from './components/design20/events20';
import ScrollToAnchor from './components/design20/anchorLink';
import livechatButtons20 from './components/design20/livechatButtons20';
import customScrollTo20 from './components/design20/customScrollTo20';
import TextModule from './components/text-module';

// 2022 Redesign
import Accordion from './components/accordion';
import AnimateTitles from './components/animateTitles';
import ApplicationProcess from './components/ApplicationProcess';
import ApplyProgress from './components/applyprogress';
import ContactBlock from './components/contactBlock';
import FavouritesButton from './components/favouritesButton';
import Hero from './components/hero';
import InfoCards from './components/infoCards';
import Map20 from './components/map20';
import Roadblock from './components/roadblock';
import RoleFinder from './components/rolefinder';
import RoleInfo from './components/role-info';
import RoleResultFavouriting from './components/roleResultFavouriting';
import RoleTypeToggle from './components/roletypetoggle';
import Training from './components/Training';

import FlashingLights from './components/flashing-lights';

//import Forms from './components/forms';
import ApplyForms from './components/applyForms';
import SelectRoleForm from './components/selectRoleForm';

//App Load
$(() => {
    const _utilities = new Utilities();
    _utilities.iOSsmothScroll();

    new CookieWarning();
    new Navigation();
    new AnimateTitles();

    if(_utilities.smartLoad('#FlashingLights')) {
        new FlashingLights();
    }

    if(_utilities.smartLoad('.contactblock-label')) {
        new ContactBlock();
    }

    //Role switch toggle
    if(_utilities.smartLoad('.toggle-switch')) {
        new Raf15sliderButton();
    }

    //Anchor link scroll on url hash
    if(window.location.hash !== undefined && window.location.hash) {
        new ScrollToAnchor();
    }

    //Accordion
    if (_utilities.smartLoad('accordion-container') || _utilities.smartLoad('accordion-wrapper')) {
        new Accordion();
    }

    //Modal
    if (_utilities.smartLoad('modal-open') && _utilities.smartLoad('modal')) {
        new Modal();
    }

    //TabedModule
    if (_utilities.smartLoad('tabed-module')) {        
        new TabedModule();
    }

    //Events Module
    if (_utilities.smartLoad('events-container')) {
        new EventsModule();
    }

    //Expanded Content Module
    if (_utilities.smartLoad('expanded-content-module') 
        || _utilities.smartLoad('content-module-15') 
        || _utilities.smartLoad('content-module-content-15') 
        || _utilities.smartLoad('role-overview-15')
        || _utilities.smartLoad('testimonial-15')
        || _utilities.smartLoad('application-role-numbers-20')
        || _utilities.smartLoad('dual-column-20')
        || _utilities.smartLoad('events-20')) {

        new ExpandedContent();
    }

    //Role Details
    if(_utilities.smartLoad('role-details-module')) {
        let delayRD = 0;

        if(_utilities.isTablet()) {
            delayRD = 350;
        }

        setTimeout(() => {
            new RoleDetails();  
        }, delayRD);
    }

    //Forms
    if (_utilities.smartLoad('.apply-select-role-form')) {
        new SelectRoleForm();
    }
    if (_utilities.smartLoad('.register-your-interest')) {
        new ApplyForms();
    }


    //Lightbox
    if(_utilities.smartLoad('lightbox-open')) {
        new LightBoxLity();
    }

    //Carousel
    if(_utilities.smartLoad('owl-carousel') || _utilities.smartLoad('slick-carousel')) {
        let delayC = 0;
        if(_utilities.isTablet()) {
            delayC = 250;
        }
        setTimeout(() => {
            new Carousel();
        }, delayC);
    }
    if(_utilities.smartLoad('videocarousel-slides')) {
        let delayC = 0;
        if(_utilities.isTablet()) {
            delayC = 250;
        }
        setTimeout(() => {
            new VideoCarousel();
        }, delayC);
    }
    if(_utilities.smartLoad('videosingle')) {
        let delayC = 0;
        if(_utilities.isTablet()) {
            delayC = 250;
        }
        setTimeout(() => {
            new VideoSingle();
        }, delayC);
    }
    if(_utilities.smartLoad('carousel30-slides')) {
        let delayC = 0;
        if(_utilities.isTablet()) {
            delayC = 250;
        }
        setTimeout(() => {
            new Carousel30();
        }, delayC);
    }

    //Light Box
    if(_utilities.smartLoad('open-lightbox') || _utilities.smartLoad('video-module')) {
        new LightBox();
    }

    //Sound Skill
    if(_utilities.smartLoad('.sound-skills-module')) {
        setTimeout(() => {
            new SoundSkills();
        }, 150);
    }

    //Tracking Codes
    if(_utilities.smartLoad('tracking-codes-click-event')) {
        new TrackingCodes();
    }

    //Header Nav Bar 2.0
    if(_utilities.smartLoad('.header-wrapper')) {
        setTimeout(() => {
            new Header();
        }, 150);
    }

    //check if ie11
    if(_utilities.ie11Check()) {
        $("body").addClass("ie11");
    }

    if(_utilities.smartLoad('.hero-section-home-20')) {
        new heroSection20();
    }

    if (_utilities.smartLoad('.airman-selection-test-20')) {
        new airmanSelectionTest20();
    }

    if (_utilities.smartLoad('.events-20')) {
        new events20();
    }
    
    if (_utilities.smartLoad('.hero-media-container')) {
        new Hero();
    }

    if (_utilities.smartLoad('.btn-20-livechat')) {
        new livechatButtons20();
    }

    if (_utilities.smartLoad('.custom-scroll-to-20')) {
        new customScrollTo20();
    }

    //Text Module Table Fix
    if(_utilities.smartLoad('.text-module')) {
        new TextModule();
    }

    if(_utilities.smartLoad('.find-your-role')) {
        new RoleFinder();
    }

    if(_utilities.smartLoad('.role-finder-result')) {
        new RoleResultFavouriting();
    }
    
    if(_utilities.smartLoad('.roadblock')) {
        new Roadblock();
    }

    if(_utilities.smartLoad('.favourites-button-container')) {
        new FavouritesButton();
    }

    if(_utilities.smartLoad('.roleinfo-details-wrapper')) {
        new RoleInfo();
    }

    if(_utilities.smartLoad('.roletype-toggle')) {
        new RoleTypeToggle();
    }

    if(_utilities.smartLoad('.training-container')) {
        new Training();
    }

    if(_utilities.smartLoad('.infocards-container')) {
        new InfoCards();
    }

    if(_utilities.smartLoad('.applicationprocess-container')) {
        new ApplicationProcess();
    }

    if(_utilities.smartLoad('.applyprogress-container')) {
        new ApplyProgress();
    }

    if(_utilities.smartLoad('.map-20')) {
        new Map20();
    }
});

